import React from 'react';
import Nav from './Nav';
import PageHeader from './PageHeader';

import ImageShibetu from "./assets/images/AdobeStock_300131097.jpg"
import ImageDiM from "./assets/images/AdobeStock_303992248.jpg"

function Released() {
  return (
    <div>
      <Nav />
      <div classNameName="wrapper">
        <PageHeader />
        <div className="section section-team-horizon text-center">
          <div className="container">
            <h2 className="title">Service</h2>
            <div className="team">

              <div className="row item">
                <div className="team-player col-md-4">
                <a target="_blank" href="https://panelrpc01.dxpl.jp/"><img alt="" src={ImageShibetu} className="img-fluid img-raised" /></a>
                </div>
                <div className="col-md-8 text-left">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <h4 className="title"><a target="_blank" href="https://panelrpc01.dxpl.jp/">計測解析サービス（士別遠隔）</a></h4>
                        <p className="category text-primary">計測・解析サービス</p>
                      </div>
                      <div className="col-md-6 text-left">
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-left">
                        <p className="description text-left">士別試験場における実車走行とドライブシミュレータの計測データを解析します。</p>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row item">
                <div className="team-player col-md-4">
                <a target="_blank" href="https://panelrpc01-dim.dxpl.jp/"><img alt="" src={ImageDiM} className="img-fluid img-raised" /></a>
                </div>
                <div className="col-md-8 text-left">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <h4 className="title"><a target="_blank" href="https://panelrpc01-dim.dxpl.jp/">計測解析サービス（DiM遠隔）</a></h4>
                        <p className="category text-primary">計測・解析サービス</p>
                      </div>
                      <div className="col-md-6 text-left">
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-left">
                        <p className="description text-left">鷺ノ宮製ドライビングシミュレータ DiM(Drivin in Motion)を使って実車とシミュデータの比較解析を行います。</p>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Released;