import React from 'react';
import Nav from './Nav';
import PageHeader from './PageHeader';
import News from './News';
import AboutNOTICE from './AboutNOTICE';
import Measure from './Measure';
import Analysis from './Analysis';
import Support from './Support';

function Top() {
  return (
    <div>
      <Nav />
      <div className="wrapper">
        <PageHeader />
        <News />
        <Support/>
      </div>
    </div>
  );
}
export default Top;