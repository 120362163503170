import React from 'react';
import NOTICE_title from './assets/images/NOTICE_title.png'
import './PageHeader.css'

function PageHeader() {
  return (
    <div className="page-header page-header-small">
      <div className="page-header-image" data-parallax="true"></div>
        <div className="content-center">
          <div className="container">
            <h1><b>OptiMeister Online</b></h1>
            {/* <img alt="" src={NOTICE_title}/>
            <h5 className="description">今までにない特別な気づきを</h5>
            <div className="text-center"></div> */}
          </div>
      </div>
    </div>
  );
}
export default PageHeader;