// =========================================================
// * Now UI Kit - v1.3.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/now-ui-kit
// * Copyright 2019 Creative Tim (http://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit/blob/master/LICENSE.md)

// * Designed by www.invisionapp.com Coded by www.creative-tim.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
