import { React, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

import blurred_image_1 from './assets/img/blurred_image_1.jpg';

function Nav() {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event);
      switch (event) {
        case "signIn":
          getUser().then(userData => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    getUser().then(userData => setUser(userData));

    return unsubscribe;
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => {
        console.log('Not signed in');
      });
  }

  function signout() {
    try {
      Auth.signOut();
    }
    catch (e) {
      console.error( "Sign Out エラー：", e.message );
    }
  }

  return (
    <nav className="navbar navbar-expand-lg bg-primary fixed-top navbar-transparent " color-on-scroll="400">
      <div className="container">
        <div className="navbar-translate">
          <a href="./" className="nav-link">User: {user ? JSON.stringify(user.attributes['email']) : "None"}</a>
          <button className="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-bar top-bar"></span>
            <span className="navbar-toggler-bar middle-bar"></span>
            <span className="navbar-toggler-bar bottom-bar"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse justify-content-end" id="navigation" data-nav-image={blurred_image_1}>
          <ul className="navbar-nav">
            <li className="nav-item">
              {user ? (
                <a className="nav-link" href="javascript:void(0);" onClick={() => signout()}>[Sign Out]</a>
              ) : (
                <span></span>
              )}
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./">TOP</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="./trial">トライアル</a>
            </li>         
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Nav;