import React from 'react';
import Nav from './Nav';
import PageHeader from './PageHeader';

import OptiMeisterCloud from "./assets/images/margin_set_horizontal2H512.png"

function Trial() {
  return (
    <div>
      <Nav />
      <div className="wrapper">
        <PageHeader />
        <div class="section section-team-horizon text-center">
          <div class="container">
            <h2 class="title">Trial</h2>
            <div class="team">

              <div class="row item">
                <div class="team-player col-md-4">
                  <a target="_blank" href="https://demo.optimeister.online/optimeister2/"><img alt="" src={OptiMeisterCloud} class="img-fluid img-raised" /></a>
                  <p class="descriptionsmall">ユーザー名：demo　パスワード：OptiMeister2</p>
                </div>
                <div class="col-md-8 text-left">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 text-left">
                        <h4 class="title"><a target="_blank" href="https://demo.optimeister.online/optimeister2/">OptiMeister Online</a></h4>
                        <p class="category text-primary">解析サービス</p>
                      </div>
                      <div class="col-md-6 text-left">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <p class="description text-left">高サンプリング周期データの解析コンテンツを豊富に搭載し、ユーザ独自の解析機能と連携、解析を自動化します。</p>  
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <p class="description text-left">E-mail:<a href="mailto:optimeister-support@ml.toyota-td.jp">optimeister-support@ml.toyota-td.jp</a></p>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* テンプレート */}
              {/* <div class="row item">
                <div class="team-player col-md-4">
                  <a target="_blank" href="システムのリンクアドレス"><img alt="" src="画像ファイルへのリンク" class="img-fluid img-raised" /></a>
                </div>
                <div class="col-md-8 text-left">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 text-left">
                        <h4 class="title"><a target="_blank" href="システムのリンクアドレス">システム名</a></h4>
                        <p class="category text-primary">カテゴリ</p>
                      </div>
                      <div class="col-md-6 text-left">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <p class="description text-left">詳細説明</p>  
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <p class="description text-left">TEL：000-0000-0000　E-mail：xxxxxxxx@xxxxx.xx.xx　担当：〇〇 〇〇</p>  
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Trial;