import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, 
  AmplifyConfirmSignUp, AmplifyForgotPassword } from "@aws-amplify/ui-react";

import React from 'react';
import Top from './Top';
import Released from './Released';
import Trial from './Trial';
import Signup from './Signup';
import Request from './Request';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import awsconfig from './aws-exports';
import { Amplify, I18n } from 'aws-amplify';

Amplify.configure(awsconfig);

function App() {
  // パラメータでコントロールできない表示文字を制御する
  // I18nをimportする
  // 以下のような形で日本語と英語を紐づけた辞書を作成する
  const dict = {
    ja: {
      "Forgot your password?": "パスワードを忘れた場合",
      "Reset password": "パスワードをリセット",
      "No account?": "アカウントを持っていない場合",
      "Create account": "サインアップ",
      "Lost your code?": "確認コードを失くした場合",
      "Resend Code": "再度入手する",
      "Back to Sign In": "サインインに戻る",
      "User does not exist.": "ユーザが登録されていません。",
    },
  };
  // 作成した辞書を渡して反映させる
  I18n.putVocabularies(dict);
  I18n.setLanguage("ja");

  return (
    <AmplifyAuthenticator>
      <AmplifySignIn
        slot="sign-in"
        headerText="サインイン画面"
        submitButtonText="サインイン"
        formFields={[
          {
            type: "username",
            label: "メールアドレス *",
            placeholder: "メールアドレスを入力",
            required: true,
          },
          {
            type: "password",
            label: "パスワード *",
            placeholder: "パスワードを入力",
            required: true,
          },
        ]}
      />
      <AmplifySignUp
        slot="sign-up"
        headerText="サインアップ"
        haveAccountText=""
        signInText="サインインに戻る"
        submitButtonText="アカウント作成"

        // formFields内に必要な項目だけを指定することで
        // 電話番号を除外できる
        formFields={[
          {
            type: "username",
            label: "メールアドレスを入力してください",
            placeholder: "メールアドレス",
            required: true,
          },
          {
            type: "password",
            label: "パスワードを入力してください",
            placeholder: "パスワード",
            inputProps: { required: true, autocomplete: "new-password" },
          },
        ]}
      />
      <AmplifyConfirmSignUp
        slot="confirm-sign-up"
        headerText = "サインアップ確認"
        submitButtonText = "確認"
        formFields={[
          {
            type: "username",
            label: "メールアドレスを入力してください",
            placeholder: "メールアドレス",
            required: true,
          },
          {
            type: "code",
            label: "確認キーを入力してください",
            placeholder: "確認キー",
            required: true,
          },
        ]}
      />
      <AmplifyForgotPassword
        headerText="パスワードリセット"
        slot="forgot-password"
        sendButtonText = "送信"
        formFields={[
          {
            type: "username",
            label: "メールアドレスを入力してください",
            placeholder: "メールアドレス",
            required: true,
          },
        ]}
      />
      <div className="app">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Top}/>
            {/* <Route path="/released" component={Released}/> */}
            <Route path="/trial" component={Trial}/>
            {/* <Route path="/signup" component={Signup}/> */}
            {/* <Route path="/request" component={Request}/> */}
          </Switch>
        </BrowserRouter>
      </div>
    </AmplifyAuthenticator>
  );
}
export default App;
