import React from 'react';

function News() {
  return (
    <div className="section section-about-us">
      <div className="container">
        <div className="row">
          <div className="col-md-12 ml-auto mr-auto">
            <h2 className="title text-center">News</h2>

            <div className="row">
              <div className="col-12 text-center ml-auto">
                2022/04/01    OptiMeisterOnline デモ環境 公開
              </div>

            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}
export default News;