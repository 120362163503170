import React from 'react';

function Support() {
  return (
    <div className="section section-support text-center">
      <div className="container">
        <h2 className="title">Support</h2>
        <div className="team">
          <div className="row">
            <div className="col-md-12">
              <p className="description">E-mail:<a href="mailto:optimeister-support@ml.toyota-td.jp">optimeister-support@ml.toyota-td.jp</a></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
export default Support;